import React from "react";
import styled from "styled-components";
import Body1 from "../../DS/Typo/Body1";
import H2 from "../../DS/Typo/H2";
import Wrapper from "../../DS/Wrapper/Wrapper";
import study from "../../assets/images/study.png";
import Table from "../Table/Table";
import H4 from "../../DS/Typo/H4";
import navigation from "../../assets/icons/location_red.svg";
import mobile from "../../assets/icons/mobile_dark_red.svg";
import mail from "../../assets/icons/mail_dark_red.svg";
import EmailSubscribe from "../EmailSubscribe/EmailSubscribe";
import LightDivider from "../LightDivider/LightDivider";
import mark from "../../assets/images/mark.png";
import { useScreenClass } from "@tinyd/usescreenclass";
import SubTitle2 from "../../DS/Typo/SubTitle2";
const MarkImg = styled.img`
  position: absolute;
  top: 150%;
  right: 0;
  width: 110px;
`;
const data = [
  {
    name: "Mitzi Smallwood",
    phone: ["256-734-5706"],
    email: "hmsmwood@gmail.com",
    address:
      "Mitzi Smallwood,1337 Elm Street S, Cullman, AL 35055.",
    year: "2023-2026",
    term: "Second Term",
    termSubTitle: "",
  },
  {
    name: "Larry Stewart",
    phone: ["256-820-7235", "256-453-0015"],
    email: "lbs1@cableone.net",
    address:
      "409 Newberry Drive Weaver, AL 36277.",
    year: "2023-2026",
    term: "Second Term",
    termSubTitle: "",
  },
  {
    name: "Cynthia Peebles",
    subName: "President",
    phone: ["205-792-0638"],
    email: "cpeeblesretired@gmail.com",
    address:
          "P.O. Box 132 Cottondale, AL 35453",
    year: "2023-2026",
    term: "First Term",
    termSubTitle: "",
  },
  {
    name: "Brenda Lockhart",
    phone: ["205-601-0248"],
    email: "brnlock8@aol.com",
    address:
      "1705 99th Street North, Birmingham, AL 35217",
    year: "2024-2027",
    term: "Second Term",
    termSubTitle: "",
  },
  {
    name: "Jean Madden",
    subName: "Secretary",
    phone: ["334-444-5293"],
    email: "proxy14@bellsouth.net",
    address: "P.O. Box 2271, Opelika, AL 36803",
    year: "2024-2027",
    term: "Second Term",
    termSubTitle: "",
  },
  {
    name: "Joy Seybold",
    subName: "Vice President",
    phone: ["205-459-3782"],
    email: " jseybold@tds.net",
    address: "616 Sund, Butler, AL 36904",
    year: "2024-2027",
    term: "Second Term",
    termSubTitle: "",
  },
  {
    name: "Gwen Peterson",
    phone: ["334 300-0636"],
    email: "gwynnovations@yahoo.com",
    address:
      "2136 Beverly Drive Montgomery, AL 336111-2704",
    year: "2022-2025",
    term: "Second Term",
    termSubTitle: "",
  },
  {
    name: "Herbert White",
    phone: ["251 343-9192", "251-454-4787"],
    email: "hkhwhite@bellsouth.net",
    address:
      "3920 Berwyn Drive South Apt. 214 Mobile, AL 36608-2163",
    year: "2022-2025",
    term: "Second Term",
    termSubTitle: "",
  },
  {
    name: "Dr. Stafford Thompson",
    phone: ["334-308-2152", "334-477-6904"],
    email: "drthompsonaera4@gmail.com",
    address:
      "703 Windsor Trace Enterprise, AL 36330",
    year: "2022-2025",
    term: "First Term",
    termSubTitle: "",
  },
  {
    name: "Vacant",
    phone: [""],
    email: "",
    address:"",
    year: "",
    term: "",
    termSubTitle: "",
  },
];

const LinkContainer = styled.div`
  margin-top: 10px;
  & > a,
  & > div {
    display: flex;
    margin-bottom: 10px;
    img {
      margin-right: 12px;
      width: 14px;
      height: 16px;
      margin-top: 6px;
    }
  }
`;

const AEAFoundationContainer = styled.div``;

const AEAFoundation = () => {
  const screen = useScreenClass();

  const isSmall = ["md", "sm", "xs"].includes(
    screen
  );
  return (
    <AEAFoundationContainer>
      <Wrapper type="m">
        <H2>Overview</H2>
        <Body1>
          The Alabama Education Retirees
          Foundation (originally named the Alabama
          Retired Teachers Foundation) was
          officially incorporated on September 29,
          1989, and received its Federal Income
          Tax exemption under 501 (c) (3) of the
          Internal Revenue Code on October 31,
          1989. The Foundation was created by the
          Alabama Retired Teachers Association,
          Inc. (ARTA), which is now the Alabama
          Education Retirees Association, Inc.
          (AERA). One of the purposes of the
          Foundation is to offer scholarships for
          those who are currently engaged in
          teaching as a career. The Foundation has
          been actively pursuing contributions to
          build a financial base that will provide
          the financial support for a series of
          scholarships awarded to educators who
          wish to pursue further training.
        </Body1>
      </Wrapper>
      <Wrapper type="m" marginTop="50px">
        <H2>Scholarship Program</H2>
        <div
          style={{
            display: isSmall ? "block" : "flex",
          }}
        >
          <Body1>
            The initial action of the Foundation
            included the creation of the
            Elementary/Secondary Education
            Scholarship Program. This program is
            designed to provide scholarships for
            educators who are currently employed
            in a state public school system
            (K-12). Scholarship recipients will
            receive training in areas of
            counseling, mathematics, science,
            computer education, library media,
            special education, English as a second
            language, or other specialized areas
            where a proven need exists. The
            Foundation’s board of directors
            determines the amount and number of
            the scholarships annually.
          </Body1>
          <img
            src={study}
            style={{
              objectFit: "contain",
              maxWidth: 356,
              width: isSmall ? 250 : "50%",
            }}
            alt="Scholarship Program"
          />
        </div>
      </Wrapper>
      <Wrapper type="m" marginTop="50px">
        <H2>Accomplishments</H2>
        <Body1>
          Each year, the Foundation awards ten
          $2,000 scholarships to active educators
          who are also AEA members. One recipient
          is chosen from each of AERA’s ten
          districts. As of May 2021, a total of
          220 scholarships have been awarded since
          the program began in 1991. Also,
          non-certified personnel are eligible to
          participate in the scholarship program
          to receive assistance in obtaining
          certification.
        </Body1>
      </Wrapper>
      <Wrapper type="m" marginTop="50px">
        <H2>Contributions</H2>
        <Body1>
          To supplement the financial support from
          state membership and local units, the
          Foundation actively seeks contributions
          to reach its goal. One significant means
          of contributing is to have the name of a
          loved one or friend included on the
          “Hall of Fame” wall located in the AERA
          state office in Montgomery for a
          contribution of $100 or more. All
          donations are tax deductible. Additional
          information may be obtained by calling
          the state office at 1-800-537-6867.
        </Body1>
      </Wrapper>
      <Wrapper type="m" marginTop="50px">
        <Table
          headers={[
            <Body1
              fontColor="white"
              style={{ fontWeight: "bold" }}
            >
              District
            </Body1>,
            <Body1
              fontColor="white"
              style={{ fontWeight: "bold" }}
            >
              Name and info
            </Body1>,
            <Body1
              fontColor="white"
              style={{ fontWeight: "bold" }}
            >
              Term
            </Body1>,
          ]}
          mobileHeaders={[
            <Body1 style={{ fontWeight: "bold" }}>
              District
            </Body1>,
            <Body1 style={{ fontWeight: "bold" }}>
              Name and info
            </Body1>,
            <Body1 style={{ fontWeight: "bold" }}>
              Term
            </Body1>,
          ]}
          cols={["6%", "47%", "47%"]}
          mobileCols={["30%", "70%"]}
          data={data.map((item, index) => [
            <H4 style={{ fontWeight: 400 }}>
              {index + 1}
            </H4>,
            <>
              <H4>{item.name}</H4>
              {item.subName ? (
                <SubTitle2>
                  {item.subName}
                </SubTitle2>
              ) : null}
              <LinkContainer>
                <div>
                  <img
                    src={mobile}
                    alt={"phone"}
                  />
                  <Body1>
                    {item.phone.join(" or ")}
                  </Body1>
                </div>
                {item.email.length ? (
                  <div>
                    <img
                      src={mail}
                      alt={"mail"}
                    />
                    <Body1
                      style={{
                        overflowWrap: "anywhere",
                      }}
                    >
                      {item.email}
                    </Body1>
                  </div>
                ) : null}
                <div>
                  <img
                    src={navigation}
                    alt={"map"}
                  />
                  <Body1>{item.address}</Body1>
                </div>
              </LinkContainer>
            </>,
            <div>
              
              <Body1>
                {item.year ? item.year : ""}{" "}
                {item.term.length ? (
                  <strong>({item.term})</strong>
                ) : null}
              </Body1>
              {item.termSubTitle.length ? (
                <Body1
                  style={{
                    fontWeight: 500,
                  }}
                >
                  {item.termSubTitle}
                </Body1>
              ) : null}
            </div>,
          ])}
        />
      </Wrapper>
      <LightDivider marginTop="60px" />

      <Wrapper
        type="m"
        marginTop="50px"
      ></Wrapper>
      <EmailSubscribe />
      <Wrapper
        type="m"
        marginTop="50px"
      ></Wrapper>
      <MarkImg src={mark} alt="AERA" />
    </AEAFoundationContainer>
  );
};

export default AEAFoundation;
