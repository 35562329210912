import React, { FC } from "react";
import styled from "styled-components";
import DS from "../../DS/DS";
import Caption from "../../DS/Typo/Caption";
import Wrapper from "../../DS/Wrapper/Wrapper";
import logo from "../../assets/images/logo.png";
import navigation from "../../assets/icons/location_dark.svg";
import mobile from "../../assets/icons/mobile_dark.svg";
import mail from "../../assets/icons/mail_dark.svg";
import { Link } from "react-router-dom";
import SubTitle2 from "../../DS/Typo/SubTitle2";
import Body1 from "../../DS/Typo/Body1";
import fb from "../../assets/icons/fb.svg";
// import ln from "../../assets/icons/ln.svg";
import ig from "../../assets/icons/ig.svg";
import { useScreenWidth } from "@tinyd/usescreensize";
import Button from "../../DS/Button/Button";

type menuItemLink = {
  url: string;
  text: string;
};
type menuItemIconLink = {
  url: string;
  icon: string;
};

const ribbonLeftData: (menuItemLink & {
  icon: string;
  target: string;
  imgStyle?: React.CSSProperties;
  imgMobileStyle?: React.CSSProperties;
})[] = [
  {
    target: "_blank",
    icon: navigation,
    text: "828 Washington Ave. Montgomery, AL 36104 United States",
    url: "https://www.google.com/maps/place/Alabama+Education+Retirees/@32.3760645,-86.2994017,17z/data=!3m1!4b1!4m5!3m4!1s0x888e8176025aec43:0x836d08d3f9788a14!8m2!3d32.37606!4d-86.297213",
  },
  {
    target: "_top",
    icon: mobile,
    text: "1-800-537-6867",
    url: "tel:1-800-537-6867",
    imgStyle: {
      top: "-6px",
      position: "relative",
    },
  },
  {
    target: "_top",
    icon: mail,
    text: "info@aerainc.org",
    url: "mailto:info@aerainc.org",
  },
];

const AboutUsLink: menuItemLink[] = [
  {
    text: "Who We Are",
    url: "/about/who_we_are",
  },
  {
    text: "AER Foundation",
    url: "/about/aea_foundation",
  },
  {
    text: "Board of Directors",
    url: "/about/board_of_directors",
  },
  {
    text: "State Committees",
    url: "/about/state_committees",
  },
  {
    text: "Local Units",
    url: "/about/local_units",
  },
  {
    text: "Staff",
    url: "/about/aera_staff",
  },
];
const QuickLinksLinks: menuItemLink[] = [
  {
    text: "Join AERA",
    url: "/join",
  },
  {
    text: "Membership",
    url: "/join",
  },
  {
    text: "Scholarships",
    url: "/scholarships",
  },
  {
    text: "Board Portal",
    url: "/",
  },
  // {
  //   text: "Media",
  //   url: "https://youtu.be/qBpuHVyeANw",
  // },
  {
    text: "AMBA Dental and Vision Benefits Exclusive to AERA Members",
    url: "https://youtu.be/4tRiFnNVm28"
  }
];

const ResourcesLinks: menuItemLink[] = [
  {
    text: "Helpful Links",
    url: "/helpful",
  },
  // {
  //   text: "Newsroom",
  //   url: "/news",
  // },
  {
    text: "Click & Save",
    url: "/click_and_save",
  },
];
const SocialLinks: menuItemIconLink[] = [
  {
    icon: fb,
    url: "https://www.facebook.com/pages/Alabama-Education-Retirees-Association/177214691802?ref=ts",
  },
  // {
  //   icon: ln,
  //   url: "/",
  // },
  {
    icon: ig,
    url: "https://www.instagram.com/educationretirees/",
  },
];

const LogoImg = styled.img`
  width: 135px;
  @media (max-width: 880px) {
    width: 125px;
  }
`;

const FooterContainer = styled.div`
  background-color: ${DS.colors.lightGray};
  padding-top: 20px;
`;

const FooterRibbon = styled.div`
  background-color: ${DS.colors.darkPurple};
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const LinksFlex = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 324px;

  a {
    display: flex;
    align-items: start;
    margin-right: 22px;
    margin-bottom: 28px;

    p {
      font-weight: normal;
    }
    img {
      margin-right: 10px;
    }
  }
`;

const QuickLinksContainer = styled.div`
  & > a {
    margin-bottom: 10px;
    display: block;
  }
  & > a:hover p {
    color: #c82c51;
  }

  margin-right: 5vw;
`;
const IconLinksContainer = styled.div`
  & > a {
    margin-right: 15px;
  }
`;

const QuickLinks: FC<{
  heading: string;
  links: menuItemLink[];
}> = ({ heading, links }) => {
  return (
    <QuickLinksContainer>
      <SubTitle2
        style={{ fontWeight: "bold" }}
        marginBottom="30px"
      >
        {heading}
      </SubTitle2>
      {links.map((link, index) => {
        return (
          <Link
            to={
              link.url.includes("://")
                ? {
                    pathname: link.url,
                  }
                : link.url
            }
            key={index}
            {...(link.url.includes("://")
              ? {
                  target: "_blank",
                }
              : {})}
          >
            <Body1>{link.text}</Body1>
          </Link>
        );
      })}
    </QuickLinksContainer>
  );
};
const IconLinks: FC<{
  heading: string;
  links: menuItemIconLink[];
}> = ({ heading, links }) => {
  return (
    <IconLinksContainer>
      <SubTitle2
        style={{ fontWeight: "bold" }}
        marginBottom="30px"
      >
        {heading}
      </SubTitle2>
      {links.map((link, index) => {
        return (
          <Link
            to={{ pathname: link.url }}
            target="_blank"
            key={index}
          >
            <img src={link.icon} alt={link.url} />
          </Link>
        );
      })}
    </IconLinksContainer>
  );
};

const DisplayFlex = styled.div`
  display: flex;
`;

const FloatingButtonCTA = styled.div`
  position: fixed;
  right: 0px;
  top: calc(50vh - 25px);
  z-index: 100;
  transform: rotate(-90deg) translateY(55px);
`;

const Footer = () => {
  const screenWidth = useScreenWidth();
  const isSmall = screenWidth < 880;
  const isXSmall = screenWidth < 615;
  return (
    <>
      {/* <FloatingButtonCTA>
        <Link
          to={{
            pathname:
              "/news",
          }}
        >
          <Button>Newsroom</Button>
        </Link>
      </FloatingButtonCTA> */}
      <FooterContainer>
        <Wrapper
          type="m"
          marginTop="20px"
          marginBottom="80px"
        >
          <DisplayFlex
            style={{
              marginTop: 40,
              flexWrap: "wrap",
              flexDirection: isSmall
                ? "column"
                : "row",
            }}
          >
            <div>
              <LogoImg
                src={logo}
                alt="AERA"
                style={{
                  marginBottom: 30,
                }}
              />
              <DisplayFlex>
                <LinksFlex>
                  {ribbonLeftData.map(
                    (link, index) => {
                      return (
                        <Link
                          to={{
                            pathname: link.url,
                          }}
                          key={index}
                          target={link.target}
                        >
                          <img
                            src={link.icon}
                            alt={link.text}
                            style={link.imgStyle}
                          />
                          <Caption fontColor="darkPurple">
                            {link.text}
                          </Caption>
                        </Link>
                      );
                    }
                  )}
                </LinksFlex>
              </DisplayFlex>
            </div>
            <DisplayFlex
              style={{
                flex: 1,
                justifyContent: isSmall
                  ? "flex-start"
                  : "flex-end",
                flexDirection: isXSmall
                  ? "column"
                  : "row",
                marginTop: isXSmall
                  ? "40px"
                  : "0px",
              }}
            >
              <DisplayFlex>
                <QuickLinks
                  heading="About Us"
                  links={AboutUsLink}
                />
                <QuickLinks
                  heading="Quick Links"
                  links={QuickLinksLinks}
                />
              </DisplayFlex>
              <DisplayFlex
                style={{
                  marginTop: isXSmall
                    ? "40px"
                    : "0px",
                }}
              >
                <QuickLinks
                  heading="Resources"
                  links={ResourcesLinks}
                />
                <IconLinks
                  heading="Follow Us"
                  links={SocialLinks}
                />
              </DisplayFlex>
            </DisplayFlex>
          </DisplayFlex>
        </Wrapper>
        <FooterRibbon>
          <Caption
            fontColor="white"
            style={{
              fontWeight: "normal",
            }}
          >
            AERAinc.org ©{" "}
            {new Date().getFullYear()} | All
            Rights Reserved
          </Caption>
        </FooterRibbon>
      </FooterContainer>
    </>
  );
};

export default Footer;
