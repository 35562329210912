import React, { FC } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Body1 from "../../DS/Typo/Body1";
import H2 from "../../DS/Typo/H2";
import H3 from "../../DS/Typo/H3";
import Wrapper from "../../DS/Wrapper/Wrapper";
import EmailSubscribe from "../EmailSubscribe/EmailSubscribe";
import LightDivider from "../LightDivider/LightDivider";
import TriColorDivider from "../TriColorDivider/TriColorDivider";
import arrow from "../../assets/icons/downArrowRed.svg";
import DS from "../../DS/DS";
import { useScreenClass } from "@tinyd/usescreenclass";
import SubTitle2 from "../../DS/Typo/SubTitle2";
import SubTitle1 from "../../DS/Typo/SubTitle1";
import H4 from "../../DS/Typo/H4";
import Button from "../../DS/Button/Button";
const ScholarshipsContainer = styled.div``;

const ScholarshipsLinksDetails = [
  // {
  //   title: "Scholarship Application",
  //   url: "https://www.aerainc.org/wp-content/uploads/2024/10/Scholarship-Application.pdf",
  // },
  // {
  //   title: "Scholarship Information",
  //   url: "https://www.aerainc.org/wp-content/uploads/2024/10/Scholarship-Information.pdf",
  // },
];

const ScholarshipsLinksContainer = styled(Link)`
  background-color: ${DS.colors.white};
  padding: 35px 50px;
  box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.16);
  transition: all 0.3s ease;
  display: flex;
  & > p {
    transition: all 0.3s ease;
  }
  & > img {
    transform: rotate(-90deg);
    margin-left: 20px;
  }
  &:hover {
    background-color: ${DS.colors.magenta};
    & > p {
      color: ${DS.colors.white};
    }
    & > img {
      filter: brightness(10);
    }
  }
`;

const ScholarshipsLink: FC<{
  title: string;
  url: string;
}> = ({ title, url }) => {
  const screen = useScreenClass();
  const isSmall = ["xs", "sm"].includes(screen);
  return (
    <ScholarshipsLinksContainer
      to={{
        pathname: url,
      }}
      target="_blank"
      style={isSmall ? { marginBottom: 30 } : { marginRight: 65 }}
    >
      <Body1>{title}</Body1>
      <img src={arrow} alt={title} />
    </ScholarshipsLinksContainer>
  );
};

const Scholarships = () => {
  const screen = useScreenClass();
  const isSmall = ["sm", "xs"].includes(screen);
  return (
    <ScholarshipsContainer>
      <TriColorDivider />
      <Wrapper type="m" marginTop="50px">
        {/* <H3
          style={{
            textAlign: "center",
          }}
          fontColor="magenta"
        >
          Scholarships Available!
        </H3> */}
        <H2
          style={{
            textAlign: "center",
          }}
        >
          AERA Offers Scholarships to Active AEA Members!
        </H2>
{/*         <br/>
        <img style={{width: "100%"}} src="https://www.aerainc.org/wp-content/uploads/2024/10/AER-Foundation-Scholarship.png"></img>
        <br/> */}
        <Body1 marginTop="40px">
          The Alabama Education Retirees Association, Inc. (AERA) founded the
          Alabama Education Retirees Foundation, Inc. (AERF) to provide
          scholarships for educators and support personnel in Alabama’s public
          schools and institutions. The program is open to certified and
          non-certified public education employees, who are employed in a state
          public school system (K-12) or state public postsecondary institution.
          Each year, ten (10) $2,000 scholarships are awarded in each of AERA’s
          ten districts.
        </Body1>
        <Body1 marginTop="10px">
          Among other requirements, an eligible educator must be a current
          Alabama Education Association (AEA) member in good standing, must be a
          resident of the state of Alabama for a period of at least 12
          consecutive months, has not received an Alabama Education Retirees
          Foundation Scholarship Award within the last five years, and must
          demonstrate both excellence and initiative in their field.
        </Body1>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <H4
            marginTop="10px"
            style={{
              textAlign: "center",
            }}
          >
            <strong>
              “Education Retirees Lending A Helping Hand…Pass It On!”
            </strong>
          </H4>
        </div>
        <div style={{display: "flex", justifyContent: "center"}}>
{/*           <Link style={{display: "inline-block", margin: "50px 10px"}} to={{ pathname: "https://www.aerainc.org/wp-content/uploads/2024/10/Scholarship-Information.pdf" }} target="_blank"> */}
            {/* <Button
              hoverColor="magenta"
              marginTop={isSmall ? "20px" : "0px"}
              style={
                isSmall
                  ? {
                    width: "200px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }
                  : {}
              }
            >Scholarship Information</Button> */}
          {/* </Link> */}
{/*           <Link style={{display: "inline-block", margin: "50px 10px"}} to={{ pathname: "https://www.aerainc.org/wp-content/uploads/2024/10/Scholarship-Application.pdf" }} target="_blank"> */}
            {/* <Button
              hoverColor="magenta"
              marginTop={isSmall ? "20px" : "0px"}
              style={
                isSmall
                  ? {
                    width: "200px",
                    marginLeft: "auto",
                    marginRight: "auto",
                  }
                  : {}
              }
            >Scholarship Application</Button> */}
          {/* </Link> */}
        </div>
        {/* <div style={{ display: "inline-block", marginTop: "20px" }}>
          <Link to={{
            pathname: "https://www.aerainc.org/wp-content/uploads/2024/10/Scholarship-Information.pdf",
          }} target="_blank">
            <Button

              hoverColor="magenta"
              style={{ display: "inline", marginRight: "10px" }}
            >
              Scholarship Information
            </Button>
          </Link>
          <Link to={{
            pathname: "https://www.aerainc.org/wp-content/uploads/2024/10/Scholarship-Application.pdf",
          }} target="_blank">
            <Button
              hoverColor="magenta"
              style={{ display: "inline", marginLeft: "10px", marginRight: "10px" }}          >
              Scholarship Application
            </Button>
          </Link>
        </div> */}
      </Wrapper>
      <LightDivider marginTop="60px" />
      <EmailSubscribe />
      <Wrapper marginTop="60px" />
    </ScholarshipsContainer>
  );
};

export default Scholarships;
